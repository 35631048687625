

import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import drywall from '../assets/images/drywall.png'
import LaborWarranty from '../components/LaborWarranty'

const Drywall = (props) => (
    <Layout>
        <Helmet>
            <title>Insulation, Drywall, & Painting</title>
            <meta name="description" content="We bundle all your 'wall' services into one, more efficient service, throughout the South Eastern part of the USA!" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Insulation, Drywall, & Painting</h1>
                        <p>$30 per Square Foot (Materials Included)</p>
                    </header>
                    
                    <span className="image main">
                    <img src={drywall} alt="" /></span><br /><br />
                    
                    <header className="major">
                        <h3>What's Included</h3>
                    </header>
                    <p>...</p><br /><br />
                    
                    <header className="major">
                        <h3>Pricing</h3>
                    </header>
                    <ul>
                        <li>How We Price It: </li>
                        <li>Competitor Pricing: </li>
                    </ul><br /><br />
                    
                    <header className="major">
                        <h3>Warranty Information</h3>
                    </header>
                    <ul>
                        <li>Materials Warranty: </li>
                        <li>{LaborWarranty}</li>
                    </ul><br />
                </div>
            </section>
        </div>

    </Layout>
)

export default Drywall